var render = function () {
  var _vm$modelo;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ShieldIcon",
      "size": "30"
    }
  }), _vm._v(" Novo Desafio ")], 1)])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon",
      "size": "30"
    }
  }), _vm._v(" Configurar Desafio " + _vm._s((_vm$modelo = _vm.modelo) === null || _vm$modelo === void 0 ? void 0 : _vm$modelo.nome) + " ")], 1)])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', [_c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Nome do Desafio")]), _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "basicInput"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "basicInput",
      "placeholder": "Inserir o nome do desafio"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Descrição do Desafio")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Inserir descrição",
      "rows": "3"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Data Início")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": _vm.configFlatPickr,
      "placeholder": "DD-MM-AAAA"
    },
    model: {
      value: _vm.form.dataInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dataInicio", $$v);
      },
      expression: "form.dataInicio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', [_c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Data Fim")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": _vm.configFlatPickr,
      "placeholder": "DD-MM-AAAA"
    },
    model: {
      value: _vm.form.dataFim,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dataFim", $$v);
      },
      expression: "form.dataFim"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Turmas"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "turmas",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.turmasOptions,
      "label": "nome",
      "multiple": "",
      "reduce": function reduce(option) {
        return option.id;
      }
    },
    model: {
      value: _vm.form.classroomIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "classroomIds", $$v);
      },
      expression: "form.classroomIds"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.modelo.hasQuiz ? _c('QuizSection', {
    attrs: {
      "form": _vm.form
    }
  }) : _vm._e(), _c('b-row', {
    staticClass: "mt-4 pb-5"
  }, [_c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" Salvar Desafio ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
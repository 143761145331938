<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="ShieldIcon" size="30" class="mr-50" /> Novo
                Desafio
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="mt-1">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Configurar Desafio {{ modelo?.nome }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h5 class="mt-2">Nome do Desafio</h5>
              <b-form-group label="" label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Inserir o nome do desafio"
                  v-model="form.name"
                />
              </b-form-group>

              <h5 class="mt-2">Descrição do Desafio</h5>
              <b-form-textarea
                id="textarea-default"
                placeholder="Inserir descrição"
                rows="3"
                v-model="form.description"
              />

              <!-- range -->

              <!-- <b-form-group>
                <h5 class="mt-2">Intervalo de tempo</h5>
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group >
                <h5 class="mt-2">Data Início</h5>
                <flat-pickr
                  v-model="form.dataInicio"
                  class="form-control"
                  :config="configFlatPickr"
                  placeholder="DD-MM-AAAA"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="mt-2">Data Fim</h5>
                <flat-pickr
                  v-model="form.dataFim"
                  class="form-control"
                  :config="configFlatPickr"
                  placeholder="DD-MM-AAAA"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Turmas">
                <v-select
                  id="turmas"
                  v-model="form.classroomIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="turmasOptions"
                  label="nome"
                  multiple
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <QuizSection v-if="modelo.hasQuiz" :form="form" />
    <!-- <UploadFotoSection  v-if="modelo.hasUploadPhoto" :form="form" />


    <b-row class="mt-1">
      <b-col>
        <b-card> </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 class="mt-1">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Exibição de Vídeo
        </h2>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group label="Titulo do video" label-for="basicInput">
                <b-form-input id="basicInput" placeholder="" />
              </b-form-group>
              <b-form-group label="URL do video" label-for="basicInput">
                <b-form-input id="basicInput" placeholder="" />
              </b-form-group>
              <label for="tags-basic">Palavras-chave</label>
              <b-form-tags v-model="value" input-id="tags-basic" class="mb-2" />
            </b-col>
            <b-col>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/lTIOrbFc7RE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Salvar Desafio
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Limpar
        </b-button>
      </b-col>
    </b-row> -->

    <b-row class="mt-4 pb-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="button-right"
          @click="submit()"
        >
          Salvar Desafio
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import challengeModelService from '@/services/challengeModelService'
import challengeService from '@/services/challengeService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
// Import Components
import QuizSection from './components/Quiz.vue'
import UploadFotoSection from './components/UploadFoto.vue'
import { desafiosEnum } from '@/desafios.enum'
import classroomService from '@/services/classroomService'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    QuizSection,
    UploadFotoSection,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormTags,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['modeloId', 'id'],
  data: () => ({
    modelo: {},
    form: {
      id: null,
      name: '',
      descriptions: '',
      questions: [],
      classroomIds: [],
      dataInicio: null,
      dataFim: null,
    },
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    value: ['dengue', 'arboviroses'],
    turmasOptions: [],
  }),
  methods: {
    getPreparedEntity() {
      return {
        ...this.form,
        modelId: this.modeloId, // QUIZ
      }
    },
    async submit() {
      try {
        showSpinner()
        const entity = JSON.parse(JSON.stringify(this.getPreparedEntity()))
        if (this.modeloId == desafiosEnum.quiz)
          await challengeService[this.id ? 'updateQuiz' : 'createQuiz'](entity)
        else await challengeService[this.id ? 'update' : 'create'](entity)

        hideSpinner()
        this.$bvToast.toast('Desafio criado com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        })
        this.$router.push({ name: 'todosdesafios' })
      } catch (error) {
        hideSpinner()
        this.$bvToast.toast('Erro ao criar Desafio!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    async getDesafioInfo() {
      try {
        const desafio = await challengeService.getById(this.id)
        this.form = desafio
        this.getModelo()
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Desafio!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    async getModelo() {
      try {
        const modelo = await challengeModelService.getById(this.modeloId)
        this.modelo = modelo
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Modelo!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    async fetchClassrooms() {
      showSpinner()
      const options = await classroomService.getAll({ itemsPerPage: 1000 })
      this.turmasOptions = options.content.map(option => ({
        id: option.id,
        nome: `${option.ano} ${option.turma} - ${option.turno} ${option.escola}`,
      }))
      hideSpinner()
    },
  },
  mounted() {
    this.fetchClassrooms()
    this.getModelo()
    this.id ? this.getDesafioInfo() : this.getModelo()
  },
  created() {},
}
</script>

<style>
.list-group-item {
  transition: all 1s;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "quiz"
    }
  }, [_c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon",
      "size": "30"
    }
  }), _vm._v(" Upload de Foto ")], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="quiz">
    <b-row>
      <b-col>
        <h2 class="mt-1">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Upload de Foto
        </h2>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BAvatar,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>